export const gateway = "https://sterling-bms-api.link.wawanesalife.com";
export const LoginUrlParameter = "https://stable-brokers.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=2oigpd9k59frf28c6qso9mgug2&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fsterling-bms.link.wawanesalife.com%2Fredirect";
export const BrokerPoolClientParameter = "2oigpd9k59frf28c6qso9mgug2";
export const BrokerPoolDomainParameter = "stable-brokers";
export const BrokerPoolParameter = "ca-central-1_Q5DSclAvd";

export const brokerBranding = {
	"sterling.link.wawanesalife.com": {
		a: {
			broker: "sterling",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://www.iubenda.com/privacy-policy/90666380/full-legal",
			scope: "sterling#branding#sterling.link.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "info@sterlingcapitalbrokers.com",
			video_link: "null",
			map2: "Suite 200 84 Chain Lake Drive,Halifax,NS,B3S 1A2",
			environment_id: "stable",
			map1: "48-209 Wicksteed Avenue,Toronto,ON,M4G 0B1",
			brands: "sterling.link.wawanesalife.com",
			map4: "Unit 110 4919-59 Street,Red Deer,AB,T4P 1M8",
			map3: "#1501-1111 West Georgia St.,Vancouver,BC,V6E 4M3",
			introduction: "null",
			phone: "1-888-213-5557",
			menuPhone: "button",
			environment: "stable",
			website: "https://www.sterlingbrokers.com/",
			ftcLink: "null",
			layout: "modern",
			g4: "G-BKLFX8DNNL",
			photo3: "show",
			photo4: "show",
			photo1: "show",
			photo2: "show",
			tagline: "Modern Benefit Solutions for Canadian Businesses",
			approve_user: "link_sterling",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "sterling.link.wawanesalife.com",
		},
		b: {
			broker: "sterling",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://www.iubenda.com/privacy-policy/90666380/full-legal",
			scope: "sterling#branding#sterling.link.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "info@sterlingcapitalbrokers.com",
			video_link: "null",
			map2: "Suite 200 84 Chain Lake Drive,Halifax,NS,B3S 1A2",
			environment_id: "stable",
			map1: "48-209 Wicksteed Avenue,Toronto,ON,M4G 0B1",
			brands: "sterling.link.wawanesalife.com",
			map4: "Unit 110 4919-59 Street,Red Deer,AB,T4P 1M8",
			map3: "#1501-1111 West Georgia St.,Vancouver,BC,V6E 4M3",
			introduction: "null",
			phone: "1-888-213-5557",
			menuPhone: "button",
			environment: "stable",
			website: "https://www.sterlingbrokers.com/",
			ftcLink: "null",
			layout: "modern",
			g4: "G-BKLFX8DNNL",
			photo3: "show",
			photo4: "show",
			photo1: "show",
			photo2: "show",
			tagline: "Modern Benefit Solutions for Canadian Businesses",
			approve_user: "link_sterling",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "sterling.link.wawanesalife.com",
		},
	},
};

//updated on Thu May 30 2024 11:04:23 GMT-0700 (Pacific Daylight Time)
